import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";

function HeroSection({ bgimage, bgimage2, bgimage3, bgimage4 }) {
  const { rpdata } = useContext(GlobalDataContext);

  const bgimages = [
    bgimage,
    bgimage2,
    bgimage3,
    bgimage4,
  ]
  return (
    <>
      <div className="pic-wrapper">
        {
          bgimages.map((item, index) => {
            return (
              <figure
                className={`pic-${index + 1}`}
                style={{ background: `url("${item}")` }}
              ></figure>
            )
          })
        }
        
        <div className="z-10 relative w-4/5 mx-auto md:h-[100vh] h-[108vh]">
          <div className="w-full h-full pb-[15%] md:pb-[10%] flex flex-col justify-end items-center">
            <h1 className="text-white text-center">
              {rpdata?.dbSlogan?.[0].slogan}
            </h1>
            <p className="text-white text-center">{rpdata?.dbValues?.[0].description}</p>
            <div className="flex justify-center md:block">
              <ButtonContent />
            </div>
          </div>
          <div className="w-1/2"></div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
